import React, { useEffect, useLayoutEffect } from "react";
// import "./Context.scoped.scss";
import "./Context.css";
import DPImg2 from '../assets/images/DPImage2.svg';
import GenuineProduct from '../assets/images/genuineProduct.svg';
import testProduct from '../assets/images/Group 19.svg';
import testProduct2 from '../assets/images/Group 19@2x.svg';
import testProduct3 from '../assets/images/Group 19@3x.svg';

import cnProduct from '../assets/images/Group 20@3x.svg';
// import FullRect from '../assets/images/fullRect.svg';
// import BottomRect from '../assets/images/bottomRect.svg';
import DefaultInfo from "./DefaultInfo";
import DetailInfo from "./DetailInfo";
import Review from "./Review";
import axios from "axios";
import { useState } from 'react';
import e from "express";

const Context = () => {

    const params = new URLSearchParams(window.location.search);

    let productId = params.get("productId");

    const urlParams = window.location.href;

    const countryValue = urlParams.slice(-2);

    const [teamName, setTeamName] = useState("");

    const [productName, setProductName] = useState("");

    const [dpImg, setDpImg] = useState("");

    const [industryName, setIndustryName] = useState("");

    const [mainCategoryName, setMainCategoryName] = useState("");

    const [subCategoryName, setSubCategoryName] = useState("");

    const [purchaseUrl, setPurchaseUrl] = useState("");

    useLayoutEffect(() => {
        callApi();
    },[])

    const callApi = async()=>{

        if (countryValue === "cn") {
            console.log("중국");
            axios.get('https://admin.snaptag.co.kr/v1/sa/product/' + productId + '/translation', {
            })
            .then(function (response) {

                console.log(response.data.cnInfo);

                setTeamName(response.data.cnInfo.teamTitle);
                setIndustryName(response.data.cnInfo.industryTitle)
                setMainCategoryName(response.data.cnInfo.mainCategoryTitle)
                setSubCategoryName(response.data.cnInfo.subCategoryTitle)

                setProductName(response.data.cnInfo.productName);
    
                setDpImg(response.data.cnInfo.productMainImageUrl);

                setPurchaseUrl(response.data.cnInfo.purchaseUrl);
            })
        } else {
            console.log("한국");

            axios.post('https://admin.snaptag.co.kr/v1/sa/auth/login', {
                email: "snaptag_official@snaptag.co.kr",
                password: "Snaptag200911!@"
            })
            .then(function (response) {
                const jwtToken = response.data.accessToken;
    
                axios.get('https://admin.snaptag.co.kr/v1/sa/product/' + productId + '/web-view', {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
                .then(function (response) {

                    console.log(response.data);

                    setTeamName(response.data.customer.name);
                    setIndustryName(response.data.industry.name)
                    setMainCategoryName('마스크팩')
                    setSubCategoryName('시트팩 / 패드')
                })    
    
                axios.get('https://admin.snaptag.co.kr/v1/sa/product-info?page=1&pageSize=100&productId=' + productId, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
                .then(function (response) {

                    console.log(response.data);

                    for (let index = 0; index < response.data.total; index++) {

                        const dataResult = response.data.productInfos[index];

                        if (dataResult.title === '제품명') {
                            setProductName(dataResult.text);
                        }

                        if (dataResult.title === '제품 메인 이미지') {
                            console.log(dataResult);
                            setDpImg(dataResult.image)
                        }

                        if (dataResult.title === '구매 사이트 링크') {
                            setPurchaseUrl(dataResult.url)
                        }
                        
                    }
                })
            })
        }
    }; 

    const handleClick = () => {
        window.location.href = purchaseUrl;
    }

    const detailInfo = () => {

        const detailInfo = document.getElementById('DetailInfo');
        const defaultInfo = document.getElementById('DefaultInfo');

        detailInfo!.style.display = 'block';
        defaultInfo!.style.display = 'none';

        const divProduct = document.getElementById('barDiv_product');
        const divDefault = document.getElementById('barDiv_default');

        divProduct!.style.height = '3px';
        divProduct!.style.background = '#111111';

        divDefault!.style.height = '1px';
        divDefault!.style.background = '#F0F1F2';
    }

    const defaultInfo = () => {

        const detailInfo = document.getElementById('DetailInfo');
        const defaultInfo = document.getElementById('DefaultInfo');

        detailInfo!.style.display = 'none';
        defaultInfo!.style.display = 'block';

        const divProduct = document.getElementById('barDiv_product');
        const divDefault = document.getElementById('barDiv_default');

        divProduct!.style.height = '1px';
        divProduct!.style.background = '#F0F1F2';

        divDefault!.style.height = '3px';
        divDefault!.style.background = '#111111';

    }

    return (
        <>
            <div className="image-context">
                { dpImg && <img src={dpImg} alt="x" title="x" className="DPIco" />}
                <img src={cnProduct} alt="fr" title="fr" style={{position: "absolute", right: "5%", marginTop: "1%"}} />
                {countryValue === "cn" ? <img src={cnProduct} alt="fr" title="fr" style={{position: "absolute", right: "5%", marginTop: "1%"}} /> : <img src={testProduct3} alt="fr" title="fr" style={{position: "absolute", right: "5%", marginTop: "1%"}} />}
            </div>
            <div className="content-context">
                {teamName}
            </div>
            <div className="content-title">
                {productName}
            </div>
            {/* <div className="price">
                26,900 원
            </div> */}
            <div className="btn-group">
                <div className="industry">
                    <span className="test">{industryName}</span>
                </div>
                <div className="button">
                    <span className="button-test">#{mainCategoryName}</span>
                </div>
                <div className="button">
                    <span className="button-test">#{subCategoryName}</span>
                </div>
            </div>
            <br style={{clear: "both"}}/>
            <br />
            <div className="hrDiv"></div>
            <div className="info_table">
                <div className="product_info" onClick={detailInfo}>
                    <span className="product_info_text">{countryValue === "cn" ? "详细信息" : "상세정보"}</span>
                    <div id="barDiv_product">

                    </div>
                </div>
                <div className="default_info" onClick={defaultInfo}>
                    <span className="default_info_text">{countryValue === "cn" ? "基本信息" : "기본정보"}</span>
                    <div id="barDiv_default">

                    </div>
                </div>
            </div>

            <div id="DetailInfo" className="DetailInfo" style={{display:'block'}}>
                <DetailInfo />
            </div>

            <div id="DefaultInfo" className="DefaultInfo" style={{display:'none'}}>
                <DefaultInfo />
            </div>

            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>

            <div className="bottomDiv">
                <button className="btn_product" onClick={handleClick}>{countryValue === "cn" ? "购买产品" : "제품 구매 하기"}</button>
            </div>
            
        </>
    )
}

export default Context;