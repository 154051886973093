import React, { useEffect, useLayoutEffect, useState } from "react";
// import "./DefaultInfo.scoped.scss";
import "./DefaultInfo.css";
import DropDownTrue from '../assets/images/dropdownTrue.svg';
import DropDownFalse from '../assets/images/dropdownFalse.svg';
import TableInfo from '../assets/images/table.svg';
import SellerInfo from '../assets/images/sellerInfo.svg';
import axios from "axios";


const DefaultInfo = () => {

    const params = new URLSearchParams(window.location.search);

    let productId = params.get("productId");

    const urlParams = window.location.href;

    const countryValue = urlParams.slice(-2);

    const [productName, setProductName] = useState(null);

    const [productType, setProductType] = useState(null);

    const [brandName, setBrandName] = useState(null);

    const [capacity, setCapacity] = useState(null);

    const [nature, setNature] = useState(null);

    const [teamName, setTeamName] = useState(null);

    const [teamAddress, setTeamAddress] = useState(null);

    const [teamHomepage, setTeamHomepage] = useState(null);

    const [teamCall, setTeamCall] = useState(null);

    useLayoutEffect(() => {
        callApi();
    },[])

    const productDiv = document.getElementById('productDiv');
    const sellerDiv = document.getElementById('sellerDiv');

    const dropdownTrue = document.getElementById('DropDownTrue');
    const dropdownFalse = document.getElementById('DropDownFalse');
    const dropdownTrueSeller = document.getElementById('DropDownTrueSeller');
    const dropdownFalseSeller = document.getElementById('DropDownFalseSeller');

    if (productDiv?.style.display == 'none') {
        dropdownTrue!.style.display = 'none';
        dropdownFalse!.style.display = 'block';
    } else if (productDiv?.style.display == 'block') {
        dropdownTrue!.style.display = 'block';
        dropdownFalse!.style.display = 'none';
    };

    if (sellerDiv?.style.display == 'none') {
        dropdownTrueSeller!.style.display = 'none';
        dropdownFalseSeller!.style.display = 'block';
    } else if (sellerDiv?.style.display == 'block') {
        dropdownTrueSeller!.style.display = 'block';
        dropdownFalseSeller!.style.display = 'none';
    };


    const handProductTrue = () => {
        const productDiv = document.getElementById('productDiv');
        const dropdownTrue = document.getElementById('DropDownTrue');
        const dropdownFalse = document.getElementById('DropDownFalse');

        productDiv!.style.display = 'none';

        dropdownTrue!.style.display = 'none';
        dropdownFalse!.style.display = 'block';
    }

    const handProductFalse = () => {
        const productDiv = document.getElementById('productDiv');
        const dropdownTrue = document.getElementById('DropDownTrue');
        const dropdownFalse = document.getElementById('DropDownFalse');

        productDiv!.style.display = 'block';

        dropdownTrue!.style.display = 'block';
        dropdownFalse!.style.display = 'none';
    }

    const handProductTrueSeller = () => {
        const sellerDiv = document.getElementById('sellerDiv');
        const dropdownTrueSeller = document.getElementById('DropDownTrueSeller');
        const dropdownFalseSeller = document.getElementById('DropDownFalseSeller');

        sellerDiv!.style.display = 'none';

        dropdownTrueSeller!.style.display = 'none';
        dropdownFalseSeller!.style.display = 'block';
    }

    const handProductFalseSeller = () => {
        const sellerDiv = document.getElementById('sellerDiv');
        const dropdownTrueSeller = document.getElementById('DropDownTrueSeller');
        const dropdownFalseSeller = document.getElementById('DropDownFalseSeller');

        sellerDiv!.style.display = 'block';

        dropdownTrueSeller!.style.display = 'block';
        dropdownFalseSeller!.style.display = 'none';
    }

    const callApi = async()=>{

        if (countryValue === "cn") {
            console.log("중국으로 들어오나???!!!");
            axios.get('https://admin.snaptag.co.kr/v1/sa/product/' + productId + '/translation', {
            })
            .then(function (response) {

                setProductName(response.data.cnInfo.productName);
                setProductType(response.data.cnInfo.productGroup);
                setBrandName(response.data.cnInfo.brandName);
                setCapacity(response.data.cnInfo.capacity);
                setNature(response.data.cnInfo.productArea);

                setTeamName(response.data.cnInfo.teamTitle);
                setTeamAddress(response.data.cnInfo.teamAddress);
                setTeamHomepage(response.data.cnInfo.teamHomepage);
                setTeamCall(response.data.cnInfo.teamCall);
            })
        } else {
            console.log("한국으로 들어오나???!!!");
            axios.post('https://admin.snaptag.co.kr/v1/sa/auth/login', {
            email: "snaptag_official@snaptag.co.kr",
            password: "Snaptag200911!@"
        })
        .then(function (response) {
            const jwtToken = response.data.accessToken;
            axios.get('https://admin.snaptag.co.kr/v1/sa/product-info?page=1&pageSize=100&productId=' + productId, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            })
            .then(function (response) {

                for (let index = 0; index < response.data.total; index++) {

                    const dataResult = response.data.productInfos[index] 

                    if (dataResult.title === '제품명') {
                        setProductName(dataResult.text);
                    }

                    if (dataResult.title === '제품분류') {
                        setProductType(dataResult.text);
                    }

                    if (dataResult.title === '브랜드명') {
                        setBrandName(dataResult.text)
                    }

                    if (dataResult.title === '용량') {
                        setCapacity(dataResult.text)
                    }

                    if (dataResult.title === '생산지') {
                        setNature(dataResult.text)
                    }
                    
                }
            })

            axios.get('https://admin.snaptag.co.kr/v1/sa/product/' + productId + '/web-view', {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            })
            .then(function (response) {

                console.log(response.data);

                setTeamName(response.data.customer.name);
                setTeamAddress(response.data.customer.address);
                setTeamHomepage(response.data.customer.homepage);
                setTeamCall(response.data.customer.call);
            })
        })
        }
    }; 
    
    return (
        <>
            <div className="blockDiv">

            </div>
            <div>
                <div className="InfoHeader">
                    <span className="text">{countryValue === "cn" ? "商品须知" : "상품 필수 정보"}</span>
                    <img src={DropDownTrue} alt="DropDownTrue" title="DropDownTrue" id="DropDownTrue" className="DropDownTrue" onClick={handProductTrue} />
                    <img src={DropDownFalse} alt="DropDownFalse" title="DropDownFalse" id="DropDownFalse" className="DropDownFalse" onClick={handProductFalse} />
                </div>
            </div>
            <div className="bottomBlock"></div>

            <br />
            
            <div className="productDiv" id="productDiv">
                <table className="defaultTable">
                    <tr className="tableTr">
                        <td className="tableTitle" style={{borderTop: "1px solid #E6E8EB"}}>
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "产品名" : "제품명"}
                        </td>
                        <td className="productName" style={{borderTop: "1px solid #E6E8EB"}}>
                            &nbsp;&nbsp;&nbsp;{productName}
                        </td>
                    </tr>
                    <tr>
                        <td className="tableTitle">
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "产品分类" : "제품분류"}
                        </td>
                        <td className="productType">
                            &nbsp;&nbsp;&nbsp;{productType}
                        </td>
                    </tr>
                    <tr>
                        <td className="tableTitle">
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "品牌" : "브랜드"}
                        </td>
                        <td className="brandName">
                            &nbsp;&nbsp;&nbsp;{brandName}
                        </td>
                    </tr>
                    <tr>
                        <td className="tableTitle">
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "容量" : "용량"}
                        </td>
                        <td className="capacity">
                            &nbsp;&nbsp;&nbsp;{capacity}
                        </td>
                    </tr>
                    <tr>
                        <td className="tableTitle">
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "生产国" : "생산지"}
                        </td>
                        <td className="production">
                            &nbsp;&nbsp;&nbsp;{nature}
                        </td>
                    </tr>
                </table>
            </div>

            <br />

            <div className="blockDiv">

            </div>

            <div>
                <div className="InfoHeader">
                    <span className="text">{countryValue === "cn" ? "公司信息" : "회사 정보"}</span>
                    <img src={DropDownTrue} alt="DropDownTrue" title="DropDownTrue" id="DropDownTrueSeller" className="DropDownTrue" onClick={handProductTrueSeller} />
                    <img src={DropDownFalse} alt="DropDownFalse" title="DropDownFalse" id="DropDownFalseSeller" className="DropDownFalse" onClick={handProductFalseSeller} />
                </div>
            </div>

            <div className="bottomBlock"></div>

            <br />

            <div className="sellerDiv" id="sellerDiv">
                <table className="sellerTable">
                    <tr>
                        <td className="tableTitle" style={{borderTop: "1px solid #E6E8EB"}}>
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "公司名" : "회사명"}
                        </td>
                        <td className="companyName" style={{borderTop: "1px solid #E6E8EB"}}>
                            &nbsp;&nbsp;&nbsp;{teamName}
                        </td>
                    </tr>
                    <tr>
                        <td className="tableTitle">
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "公司地址" : "회사 주소"}
                        </td>
                        <td className="companyAddress">
                            &nbsp;&nbsp;&nbsp;{teamAddress}
                        </td>
                    </tr>
                    <tr>
                        <td className="tableTitle">
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "公司网页" : "회사 홈페이지"}
                        </td>
                        <td className="homepage">
                            &nbsp;&nbsp;&nbsp;{teamHomepage}
                        </td>
                    </tr>
                    <tr>
                        <td className="tableTitle">
                            &nbsp;&nbsp;&nbsp;{countryValue === "cn" ? "客服中心电话号码" : "고객센터"}
                        </td>
                        <td className="customerCenter">
                            &nbsp;&nbsp;&nbsp;{teamCall}
                        </td>
                    </tr>
                </table>
            </div>

            <div className="footerBlock">
            </div>
            
        </>
    )

}

export default DefaultInfo;