import React, { useLayoutEffect, useState } from "react";
// import "./DetailInfo.scoped.scss";
import "./DetailInfo.css";
import axios from "axios";

const DetailInfo = () => {

    const params = new URLSearchParams(window.location.search);

    let productId = params.get("productId");

    const urlParams = window.location.href;

    const countryValue = urlParams.slice(-2);

    const [subImg, setSubImg] = useState(null);

    useLayoutEffect(() => {
        callApi();
    },[])

    const callApi = async () => {

        if (countryValue === "cn") {
            axios.get('https://admin.snaptag.co.kr/v1/sa/product/' + productId + '/translation', {
            })
            .then(function (response) {
                setSubImg(response.data.cnInfo.productSubImageUrl);
            })
        } else {
            console.log("한국");

            axios.post('https://admin.snaptag.co.kr/v1/sa/auth/login', {
                email: "snaptag_official@snaptag.co.kr",
                password: "Snaptag200911!@"
            })
            .then(function (response) {
                const jwtToken = response.data.accessToken;
                axios.get('https://admin.snaptag.co.kr/v1/sa/product-info?page=1&pageSize=100&productId=' + productId, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
                .then(function (response) {

                    for (let index = 0; index < response.data.total; index++) {
    
                        const dataResult = response.data.productInfos[index] 
    
                        if (dataResult.title === '제품 서브 이미지2') {
                            setSubImg(dataResult.image);
                        }
                        
                    }
                })
            })
        }
    };

    return (
        <>
            <div style={{marginTop: '2px'}}>

            </div>
            <div>
                { subImg && <img src={subImg} alt="ImageUrl" title="ImageUrl" className="ImageUrl" />}
            </div>
        </>
    )
}

export default DetailInfo;