// import "./Header.scoped.scss";
import "./Header.css";
import XImg from '../assets/images/03 ic.svg';
// import dancingWahle from '../assets/images/dancingWahle.svg';
import dancingWahleTitle from '../assets/images/brandDancingWahle.svg';
import urlMenuIcon from '../assets/images/urlMenuIcon.svg';
import { useLayoutEffect, useState } from "react";
import axios from "axios";


const Header = () => {

    const params = new URLSearchParams(window.location.search);

    let productId = params.get("productId");

    const urlParams = window.location.href;

    const countryValue = urlParams.slice(-2);

    const [instaUrl, setInstaUrl] = useState(null);

    const [facebookUrl, setFacebookUrl] = useState(null);

    const [utubeUrl, setUtubeUrl] = useState(null);

    const [brandName, setBrandName] = useState(null);

    useLayoutEffect(() => {
        callApi();
    },[])

    const callApi = async()=>{

        if (countryValue === "cn") {
            axios.get('https://admin.snaptag.co.kr/v1/sa/product/' + productId + '/translation', {
            })
            .then(function (response) {
                setBrandName(response.data.cnInfo.brandName)
                setInstaUrl(response.data.cnInfo.instagramUrl);
                setFacebookUrl(null);
                setUtubeUrl(null);
            })
        } else {
            axios.post('https://admin.snaptag.co.kr/v1/sa/auth/login', {
                email: "snaptag_official@snaptag.co.kr",
                password: "Snaptag200911!@"
            })
            .then(function (response) {
                const jwtToken = response.data.accessToken;
                axios.get('https://admin.snaptag.co.kr/v1/sa/product-info?page=1&pageSize=100&productId=' + productId, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
                .then(function (response) { 

                    for (let index = 0; index < response.data.total; index++) {
    
                        const dataResult = response.data.productInfos[index] 
    
                        if (dataResult.title === '브랜드명') {
                            setBrandName(dataResult.text);
                        }
    
                        if (dataResult.title === '인스타그램 링크') {
                            setInstaUrl(dataResult.url);
                        }
    
                        if (dataResult.title === '페이스북 링크') {
                            setFacebookUrl(dataResult.url)
                        }
    
                        if (dataResult.title === '유튜브 링크') {
                            setUtubeUrl(dataResult.url)
                        }
                        
                    }
                })
            })
        }
    };
    
    const handleDropDown = () => {
        const menu = document.getElementById('menu');
        const insta = document.getElementById('instaUrl');
        const facebook = document.getElementById('facebookUrl');
        const utube = document.getElementById('utubeUrl');

        if (menu?.style.display == 'none') {
            if (instaUrl == null) {
                insta!.style.display = 'none';
            }

            if (facebookUrl == null) {
                facebook!.style.display = 'none';
            }

            if (utubeUrl == null) {
                utube!.style.display = 'none';
            }
            menu!.style.display = 'block';
        } else if (menu?.style.display == 'block') {
            menu!.style.display = 'none';
        }
    }

    return (
        <header>
            <div className="header-top">
                
            </div>
            <div className="header-bottom">

                <a className="imageProduct" id="menuATag" onClick={handleDropDown}>
                    <img src={urlMenuIcon} alt="urlMenuIcon" title="urlMenuIcon" className="urlMenuIcon" />
                </a>

                <div className="menu" id="menu" style={{display: 'none'}}>
                    <div className="pin" id="instaUrl">
                        {countryValue === "cn" ? "Instagram" : "인스타그램"}
                    </div>
                    <div className="dropBlock"></div>
                    <div className="pin" id="facebookUrl">
                        {countryValue === "cn" ? "Facebook" : "페이스북"}
                    </div>
                    <div className="dropBlock"></div>
                    <div className="pin" id="utubeUrl">
                        {countryValue === "cn" ? "YouTube" : "유튜브"}
                    </div>
                </div>
                <a className="imageProduct">
                    <span className="dancingTitle">{brandName}</span>
                </a>
                
                <a className="imageProduct">
                    <img src={XImg} alt="x" title="x" className="xIco" />
                </a>
            </div>
        </header>
    )
}
export default Header;