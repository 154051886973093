import './App.css';
import Header from './component/Header';
import Context from './component/Context';

function App() {

  return (
    <div className="App">
      <Header />
      <Context />
    </div>
  );
}

export default App;
